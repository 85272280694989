@import "./../../../bower_components/bootstrap/scss/bootstrap-reboot";

body {
  min-height: 75rem;
  padding-top: 3.5rem;
}

img {
  max-width: 100%;
  height: auto;
}

#logo {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

#left {
  position: fixed;
  top: 3.5rem;
  left: 0;
  height: 100%;
}

#right {
  position: fixed;
  top: 3.5rem;
  right: 0;
  height: 100%;
}

#left, #right {
  .inside, img {
    height: 100%;
    width: auto;
  }
}

#footer {
  font-family: 'Bangers', cursive;
  font-size: 2rem;
  font-weight: bold;
  white-space: nowrap;
  position: relative;
  z-index: 10;
}

#footer a {
  color: #7fb24a;
}

#footer img {
  vertical-align: bottom;
  max-height: 85px;
  width: auto;
}

#footer .inside {
  background: #fff;
  max-width: 90%;
  border-top: 1px #7fb24a solid;
}

#container .inside {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

strong {
  font-weight: bold;
}

.display-4 {
  font-size: 3rem;
}

@include media-breakpoint-up(sm) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.featurette-divider {
  margin: 5rem 0;
}

#main {
  padding-bottom: 190px;
}

.mod_article div.row.featurette:last-child {
  margin-bottom: 2rem;
}

#legal-notice {
  margin-bottom: 5rem;
}

.borderimg img {
  border: 1px solid #000;
}

.img-thumbnail {
  border: none;
}

.back-to-top {
  width: 40px;
  padding-top: 0;
  background: #fff;
  position: fixed;
  bottom: 49px;
  right: 20px;
  text-align: center;
  color: #000;
  font-size: 2em;
  border: 3px solid #000;
  z-index: 100;
  height: 40px;
  line-height: 39px;
}

.mascotname {
  margin-left: -13px;
}

@media (min-width: 1200px) {
  .h-nowrap h1 {
    white-space: nowrap;
  }
}